import React from 'react';
import diagImage from './chartexplode_resized.png'
import yellowArrow from "./yellowarrow.png";

class Block2 extends React.Component {
    render() {
        return (
            <div className="row blok2">
                <div className="col">
                    <div className="blok2text">‘Everything transaction’ is about digitisation and how the Internet offers ever more far-reaching possibilities for that.</div>
                    <div className="blok2text text2">On this ‘online platform’ we will walk you through the three development stages of the Internet, just like in the book. We first look at 'information' followed by 'interaction' and, finally, 'transaction', given sufficient trust.</div>
                    <div className="blok2text text3">This book helps leaders to better navigate the digital world, from the unique perspective  of  ‘transactions’.</div>
                    <img className="img-fluid yellowarrow" src={yellowArrow} alt="Error" />

                </div>
                <div className="col diagImageCol">
                    <img src={diagImage} className="img-responsive diagramImage" alt="Image"/>
                </div>
            </div>

        );
    }
}

export default Block2;