import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor'


class Block4Mobile extends React.Component {
    render() {
        return (
            <div>
                <div className="row blok4mobilegoud">
                    <div className="col">
                        <div className="blok4mobiletext">
                            <b>SHIKKO NIJLAND</b> is CEO en managing partner van INNOPAY en heeft meer dan 20 jaar internationale ervaring in management consulting, opgedaan bij EY, KPMG en Accenture.
                        </div>
                    </div>
                    <div className="col">
                        <div className="blok4mobiletext">
                            <b>CHIEL LIEZENBERG</b> begon INNOPAY als hands-on ondernemer met een passie voor technologie, product design, start-up en verbinding. Hij is houder van meerdere octrooien.
                        </div>
                    </div>
                    <div className="col">
                        <div className="blok4mobiletext drie">
                            <b>DOUWE LYCKLAMA</b> is oprichter van INNOPAY en thought leader in betalen, factureren, identity, data delen en toepasselijke regelgeving, met een uitgebreid netwerk in fintech.
                        </div>
                    </div>
                </div>
                <div className="row blok4mobile">
                    <div className="col-12">

                    </div>
                </div>
            </div>
        );
    }
}

export default Block4Mobile;