import React from 'react';
import QuoteCarouselMobile from './QuoteCarouselMobile'

class Block5Mobile extends React.Component {
    state = {
        quoteText1: undefined,
        quoteAuteur1: undefined,
        quoteImage1: undefined,

        quoteText2: undefined,
        quoteAuteur2: undefined,
        quoteImage2: undefined,

        quoteText3: undefined,
        quoteAuteur3: undefined,
        quoteImage3: undefined,

    }

    render() {
        return (
            <div className="blok5">
                <div className="row noMarginSides noPaddingSides">
                    <div className="col">
                        <div className="informatieTitel zwart">Interaktion</div>
                        <div className="interactieSubtitel">Schreiben Sie selbst die neusten Nachrichten</div>
                        <div className="interactieSubtitel hashtag">#everything<br/>transaction</div>
                    </div>
                </div>
                <div className="row carouselRow noPaddingSides noMarginSides">
                    <div className="col">
                        <QuoteCarouselMobile/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Block5Mobile;
