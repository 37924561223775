import React from 'react';
import twitterImg from "./twitter.png";
import linkedinImg from "./linkedin.png";
import facebookImg from "./facebook.png";
import mailImg from "./mail.png";
import phoneImg from "./phone.png";
import MailchimpSubscribe from "react-mailchimp-subscribe"

const CustomForm = ({ status, message, onValidated }) => {
    let email, name;
    const submit = () =>
        email &&
        email.value.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email.value,
        });

    function Error(props) {
        const m = props.m;
        if (m.includes('already subscribed')) {
            return <div>
                <input ref={node => (email = node)} type="email" className="blok6input" placeholder="Already subscribed"></input>
                <button className="blok6button" onClick={submit}>Send</button>
            </div>;
        } else if (m.includes('too many recent signup requests')) {
            return <div>
                <input ref={node => (email = node)} type="email" className="blok6input"
                       placeholder="Too many tries"></input>
                <button className="blok6button" onClick={submit}>Send</button>
            </div>;
        } else {
            return <div>
                <input ref={node => (email = node)} type="email" className="blok6input"
                       placeholder="Something went wrong"></input>
                <button className="blok6button" onClick={submit}>Send</button>
            </div>;
        }
    }

    return (
        <div>
           {status === "error" && (
              /*  <div>
                    <input ref={node => (email = node)} type="email" className="blok6input" placeholder={message}></input>
                    <button className="blok6button" onClick={submit}>Verstuur</button>
                </div> */
              <Error m={message} />
            )}
            {status === "success" && (
                <div className="formSuccessText">
                    Thank you! <br/>We'll keep you updated.
                </div>
            )}
            {status === null && (
                <div>
                    <input ref={node => (email = node)} type="email" className="blok6input" placeholder="Email address"></input>
                    <button className="blok6button" onClick={submit}>Send</button>
                </div>
            )}

        </div>
    );
};


class Block6 extends React.Component {
    // TODO Engelse vertaling mailto

    // LISTID: 6e3e87db68

 //   const url = "//xxxx.us13.list-manage.com/subscribe/post?u=zefzefzef&id=fnfgn";

    render() {
        const url =
            "https://everyhingtransaction.us19.list-manage.com/subscribe/post?u=fa670b7ac877719b982e7aea5&amp;id=d665685089";
        return (
            <div className="row blok6">
                <div className="col blok6column">
                    <div className="row">
                        <div className="blok6text">Get in touch! <br/>Call, mail or follow us</div>
                    </div>
                    <div className="row">
                        <div className="col socialCol">
                            <a target="_blank" href="http://twitter.com/everythingtrx">
                                <img className="twitterImg" src={twitterImg} alt="Error" />
                            </a>
                            <a href="mailto:book@everythingtransaction.com?Subject=Interaction%20about%20‘Everything transaction’">
                                <img className="mailImg" src={mailImg} alt="Error" />
                            </a>
                            <a target="_blank" href="http://linkedin.com/company/everythingtrx/">
                                <img className="linkedinImg" src={linkedinImg} alt="Error" />
                            </a>
                            <a target="_blank" href="http://facebook.com/everythingtrx/">
                                <img className="facebookImg" src={facebookImg} alt="Error" />
                            </a>
                            <a href="tel:+31206580651">
                                <img className="phoneImg" src={phoneImg} alt="Error" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col blok6column twee">
                    <div className="blok6text">Stay informed</div>
                    <MailchimpSubscribe
                        url={url}
                        render={({ subscribe, status, message }) => (
                            <CustomForm
                                status={status}
                                message={message}
                                onValidated={formData => subscribe(formData)}
                            />
                        )}
                    />
                </div>
            </div>
        );
    }
}

export default Block6;