import config from '../config';

export function load(callback) {
    window.gapi.client.load("sheets", "v4", () => {
        window.gapi.client.sheets.spreadsheets.values
            .get({
                spreadsheetId: config.spreadsheetId,
                range: "Sheet1!A2:E"
            })
            .then(
                response => {
                  //  console.log(response.result.values);
                    const data = response.result.values;
                    const quotes = data.map(quote => ({
                        author: quote[0],
                        text: quote[1],
                        imageurl: quote[2],
                        tweetlink: quote[3],
                        enabled: quote[4]
                    })) || [];
                    callback({
                        quotes
                    });
                },
                response => {
                    callback(false, response.result.error);
                }
            );
    });
}