import React, { Component } from 'react';
import {Navbar, Nav, NavItem} from 'react-bootstrap'
import Block9 from './components_engels/Block9'
import Block8 from './components_engels/Block8';
import Block7 from './components_engels/Block7';
import Block6Variatie from './components_engels/Blok6Variatie';
import Block5Mobile from './components_engels/Block5Mobile';
import Block5 from './components_engels/Block5';
import Block4Mobile from './components_engels/Block4Mobile';
import Block4 from './components_engels/Block4';
import Block32 from './components_engels/Block3-2'
import Block3 from './components_engels/Block3';
import Block2Mobile from './components_engels/Block2Mobile';
import Block2 from './components_engels/Block2';
import Block1 from './components_engels/Block1';
import './App.css';
import { Parallax, Background } from 'react-parallax';
import ScrollableAnchor from 'react-scrollable-anchor'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { configureAnchors } from 'react-scrollable-anchor'
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";


class EN extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false
        };

        this.updatePredicate = this.updatePredicate.bind(this);
    }
    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
        document.title = "Everything transaction";

        ReactGA.initialize('UA-130278066-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
    }

    updatePredicate() {
        this.setState({ isMobile: window.innerWidth < 768 });
    }

    render() {
        const isMobile = this.state.isMobile;
        configureAnchors({keepLastAnchorHash: true, scrollUrlHashUpdate: false})
        return (
            <div className="App">
                <Helmet>
                    <title>Everything transaction</title>
                    <meta name="description" content="About data, trust and the unprecedented opportunities of the transactional internet." />
                    <meta property="og:title" content="Everything transaction"/>
                    <meta property="og:image" content="http://www.everythingtransaction.com/everythingtrxpreview.png"/>
                    <meta name="twitter:title" content="Everything transaction"/>
                    <meta name="twitter:description" content="About data, trust and the unprecedented opportunities of the transactional internet."/>
                    <meta name="twitter:image" content="http://www.everythingtransaction.com/everythingtrxpreview.png"/>
                    <meta name="twitter:card" content="summary_large_image"/>
                </Helmet>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
                      integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u"
                      crossOrigin="anonymous"/>

                    <link rel="stylesheet"
                          href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap-theme.min.css"
                          integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp"
                          crossOrigin="anonymous"/>



                <div className="main mainwrapper">
                    <div className="container-fluid noPaddingSides">
                        <div className="col"></div>
                        <div className="col">
                            <div className="mainCol">
                                    <Block1/>
                                <div className="circle">
                                        <div className="circleText">A <br/>book by</div>
                                        <div className="circleTextSmall">Chiel Liezenberg<br/>Douwe Lycklama<br/>Shikko Nijland</div>
                                </div>
                                {isMobile ? (
                                        <Block2Mobile/>
                                ) : (
                                        <Block2/>
                                )}
                                    <Block3/>
                                <div id="award">
                                    <Block32/>
                                </div>
                                {isMobile ? (
                                        <Block4Mobile/>
                                ) : (
                                        <Block4/>
                                )}

                                {isMobile ? (
                                        <Block5Mobile/>
                                ) : (
                                        <Block5/>
                                )}
                                    <Block6Variatie/>
                                <Block7/>
                                    <Block8/>
                                <Block9/>
                            </div>
                        </div>
                        <div className="col"></div>
                    </div>
                </div>
            </div>
    );
  }
}

export default EN;
