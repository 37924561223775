import React from 'react';
import ScrollableAnchor from "react-scrollable-anchor";
import Block5Mobile from "./Block5Mobile";
import Block5 from "./Block5";
import diagImage from "./chartexplode_resized.png";

class Block2Mobile extends React.Component {

    render() {
        return (
            <div className="row blok2">
                <div className="col">
                    <div className="blok2text">„Alles Transaktion“ behandelt Digitalisierung und die immer weitreichenderen Möglichkeiten, die das Internet für Digitalisierung bietet.</div>
                    <div className="blok2text text2">Wie im Buch durchlaufen wir auf dieser „Online-Plattform“ mit Ihnen die drei Entwicklungsstufen des Internets. Wir beginnen zunächst mit „Information“. Dann folgt „Interaktion“ und schließlich – wenn genügend Vertrauen vorhanden ist – „Transaktion“.</div>
                    <div className="diagramImage"></div>
                </div>
                <div className="col">
                    <img src={diagImage} className="img-responsive" alt="Image"/>
                    <div className="blok2text text3">Dieses Buch hilft Führungskräften, sich besser in der digitalen Welt zurechtzufinden – aus der einzigartigen Sicht von „Transaktionen“.</div>
                </div>
            </div>
        );
    }
}

export default Block2Mobile;
