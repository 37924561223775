import React from 'react';
import awardImage from "./award_image.png";

class Block3 extends React.Component {
    render() {
        return (
            <div className="blok32">
                <a href="https://www.managementboekvanhetjaar.nl" target="_blank">
                    <div className="row noMarginSides">
                        <div className="col-12">
                            <div className="award_titel">„Management Book of the Year“ 2019</div>
                        </div>
                        <div className="col-4 blok32col">
                            <div>
                                <div>
                            <img className="img-fluid award_image" src={awardImage} alt="Error" />
                                </div>
                            </div>
                        </div>
                        <div className="col-8 blok32col">
                            <div className="award_text">„Weil das Thema von großer gesellschaftlicher und wirtschaftlicher Bedeutung ist; weil praktisch jedes Unternehmen das Thema verstehen muss; wegen der tiefgreifenden Analyse; weil die Entwicklung klar in eine Richtung zeigt; wegen der Agenda, die ihre Verantwortung gegenüber vielen verschiedenen Stakeholdern aufzeigt; und obwohl dieses Buch im Vergleich zu allen anderen berücksichtigten Büchern die Leser vielleicht am meisten fordert – deshalb hat sich die Jury einstimmig für ‚Alles transactie‘ (‚Alles Transaktion‘) von Chiel Liezenberg, Douwe Lycklama und Shikko Nijland entschieden“, so die Begründung der Jury.
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        );
    }
}

export default Block3;
