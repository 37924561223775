import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import NL from './NL';
import EN from './EN';
import DE from './DE';
import PrivacyNL from './PrivacyNL';
import PrivacyEN from './PrivacyEN';

class App extends Component {
    render() {
        const App = () => (
            <div>
                <Switch>
                    <Route exact path="/" component={EN}/>
                    <Route path="/en" component={EN}/>
                    <Route path="/nl" component={NL}/>
                    <Route path="/de" component={DE}/>
                    <Route path="/privacynl" component={PrivacyNL}/>
                    <Route path="/privacyen" component={PrivacyEN}/>
                </Switch>
            </div>
        )
        return (
            <Switch>
                <App/>
            </Switch>
        );
    }
}

export default App;
