import React from 'react';
import innologo from './innopaylogo.png';
import blackArrow from "./blackarrow2.png";

class Block8 extends React.Component {

    render() {
        return (
            <div className="blok8 marginmin15">
                <div className="row noPaddingSides noMarginSides">
                    <div className="col-12">
                        <img className="img-fluid blackarrow" src={blackArrow} alt="Error" />
                    </div>
                    <div className="col">
                        <div className="terzaketitel">Down to business!</div>
                    </div>
                </div>
                {/*<div className="row noPaddingSides noMarginSides">*/}
                    {/*<div className="col">*/}
                        {/*<div className="comingq1">The book is available only in Dutch, for now</div>*/}
                    {/*</div>*/}
                {/*</div>*/}
                <div className="row justify-content-around noPaddingSides noMarginSides">
                    <div className="col-4 terzakecol">
                        <div className="terzakesubtitel">Buy book now</div>
                        <a className="directbuybutton" target="_blank" href="https://www.amazon.com/dp/B07YZ8ND93">Amazon.com</a>
                        <a className="directbuybutton directbuy_second" target="_blank" href="https://www.managementboek.nl/boek/9789401457095/alles-transactie-chiel-liezenberg?affiliate=6489">Managementboek.nl</a>

                    </div>
                    <div className="col-4 terzakecol">
                        <div className="terzakesubtitel">View book first</div>
                        <div className="terzaketext">Obviously, ‘Everything transaction’ is also for sale in book stores. There you can browse through this beautiful book and be inspired.  </div>
                    </div>
                </div>
                <div className="row noPaddingSides noMarginSides">
                    <div className="col-12">
                        <div className="terzakesubtitel">Advise on 'Everything transaction'</div>
                    </div>
                </div>
                <div className="row justify-content-around noPaddingSides noMarginSides">
                    <div className="col-5 terzakecol">
                        <div className="terzaketext nonbold">INNOPAY’s been helping organisations for over 20 years in bringing ‘Everything transaction’ into practice. Feel free to contact us.</div>
                    </div>
                    <div className="col-3 terzakecol">
                        <a href="http://innopay.com" target="_blank">
                            <img className="img-fluid innologo" src={innologo} alt="Error"/>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Block8;