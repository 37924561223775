import React from 'react';
import ReactDOM from 'react-dom';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class CookieBar extends React.Component {
    removeBar = () => {
        cookies.set('cookies', 'true', { path: '/' });
        console.log(cookies.get('cookies')); // Pacman
        let mountNode = ReactDOM.findDOMNode(this.refs.cookiebar);
        mountNode.parentNode.removeChild(mountNode);
    }

    link = () => {
        window.location.href = '/privacyen';
    }

    componentDidMount() {
        const cook = cookies.get('cookies');
        if (cook === 'true') {
            if (this.refs.cookiebar !== null) {
                let mountNode = ReactDOM.findDOMNode(this.refs.cookiebar);
                mountNode.parentNode.removeChild(mountNode);
            }
        }
    }

    render() {
        const cook = cookies.get('cookies');
        if (cook == 'true') {
            return (<div ref="cookiebar">

            </div>);
        } else {
            return (

                <div ref="cookiebar" className="col-12 cookiesbar">
                    <div className="cookiesbartext">Wir verwenden Cookies. Weitere Informationen in unserer <li className="privacylink" onClick={this.link}>Datenschutzerklärung.</li></div>

                    <div className="cookiewrapper">
                        <button onClick={this.removeBar} className="cookiesButton"><div className="cookiebuttontext">OK</div></button>
                    </div>
                </div>

            );
        }
    }
}

export default CookieBar;
