import React from 'react';
import Quote from "./Quote";
import { Carousel } from 'react-bootstrap';
import nextIconImg from './arrow-right.png';
import prevIconImg from './arrow-left.png';
import config from '../config';
import { load } from './spreadsheet';

class QuoteCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nextIcon: <img src={nextIconImg} className="carouselButton"></img>,
            prevIcon: <img src={prevIconImg}></img>,
            quotes: [],
            error: null
        }
    }

    componentDidMount() {
        window.gapi.load("client", this.initClient);
    }

    initClient = () => {
        // 2. Initialize the JavaScript client library.
        window.gapi.client
            .init({
                apiKey: config.apiKey,
                // Your API key will be automatically added to the Discovery Document URLs.
                discoveryDocs: config.discoveryDocs
            })
            .then(() => {
                // 3. Initialize and make the API request.
                load(this.onLoad);
            });
    };

    onLoad = (data, error) => {
        if (data) {
            const quotes = data.quotes;
            this.setState({ quotes });
        } else {
            this.setState({ error });
        }
    };


    render() {
        const {nextIcon,prevIcon}=this.state;
        const CLIENT_ID = "290807867602-oie116fdt7u1het4coekhfivp44lje08.apps.googleusercontent.com";
        const API_KEY = "AIzaSyDnV2xKNHYh9ZCsKkt4q79pfReXdFaXuZc";
        const SPREADSHEET_ID = "12NH1Z9D0eUnpGLf0tRZrOZITBrMWdT0u2z5euy1nosI";
        const SPREADSHEET_NAME = "everythingtrxquotes";

        const { quotes, error } = this.state;

        return (
            <Carousel interval={null} indicators={false} nextIcon={nextIcon} prevIcon={prevIcon} >

                {quotes.map((quote, i) => (
                    this.renderQuoteItem(quote, i)
                ))}

            </Carousel>
        );
    }

    renderQuoteItem(quote, i) {
        if (quote.enabled === "y") {
            return(
                <Carousel.Item key={i}>
                    <a href={quote.tweetlink} target="_blank">
                        <Quote quoteText={quote.text} quoteAuteur={quote.author} quoteImage={quote.imageurl} />
                    </a>
                </Carousel.Item>
            );

        }
    }
}

export default QuoteCarousel;