import React from 'react';

class Block4Mobile extends React.Component {
    render() {
        return (
            <div>


                <div className="row blok4mobilegoud">
                    <div className="col">
                        <div className="blok4mobiletext">
                            <b>SHIKKO NIJLAND</b> ist CEO und Managing Partner von INNOPAY und verfügt über mehr als 20 Jahre internationale Erfahrung in der Managementberatung, die er in seiner Zeit bei EY, KPMG und Accenture gesammelt hat.
                        </div>
                    </div>
                    <div className="col">
                        <div className="blok4mobiletext">
                            <b>CHIEL LIEZENBERG</b> hat INNOPAY gegründet und ist ein praxisorientierter Unternehmer, der für Technologie, Produktdesign, Start-ups und „Verbindungen“ brennt. Er hält verschiedene Patente.
                        </div>
                    </div>
                    <div className="col">
                        <div className="blok4mobiletext drie">
                            <b>DOUWE LYCKLAMA</b> ist Mitgründer von INNOPAY und ein Vordenker mit Blick auf Zahlungen, Rechnungsstellung, Identität, das Teilen von Daten und die einschlägigen Vorschriften. Er verfügt über ein umfangreiches Netzwerk in der FinTech-Welt.
                        </div>
                    </div>
                </div>
                <div className="row blok4mobile">
                    <div className="col-12">

                    </div>
                </div>
            </div>
        );
    }
}

export default Block4Mobile;
