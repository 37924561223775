import React from 'react';
import innologo from './innopaylogo.png';
import blackArrow from "./blackarrow2.png";

class Block8 extends React.Component {
    bgStyle = {
        background: 'white'
    };

    render() {
        return (
            <div className="marginmin15" style={this.bgStyle}>
                <div>
                    <div>
                        <div>
                            <div>
                <div className="row noPaddingSides noMarginSides">
                    <div className="col-12">
                        <img className="img-fluid blackarrow" src={blackArrow} alt="Error" />
                    </div>
                    <div className="col">
                        <div className="terzaketitel"> Dan nu ter zake! </div>
                    </div>
                </div>
                <div className="row justify-content-around noPaddingSides noMarginSides">
                    <div className="col-4 terzakecol">
                        <div>
                            <div>
                        <div className="terzakesubtitel">Boek direct kopen</div>
                        <a className="directbuybutton" target="_blank" href="https://www.managementboek.nl/boek/9789401457095/alles-transactie-chiel-liezenberg?affiliate=6489">Managementboek.nl</a>
                                <a className="directbuybutton directbuy_second" target="_blank" href="https://www.amazon.com/dp/B07YZ8ND93">Amazon.com</a>
                        </div>

                        </div>
                    </div>
                    <div className="col-4 terzakecol">
                        <div className="terzakesubtitel">Boek eerst bekijken</div>
                        <div className="terzaketext">Natuurlijk is 'Alles transactie' ook te koop in de boekhandel. Daar kun je op je gemak door dit mooie boek bladeren en je laten inspireren.</div>
                    </div>
                </div>
                <div className="row noPaddingSides noMarginSides">
                    <div className="col">
                        <div className="terzakesubtitel">Advies bij 'Alles transactie'</div>
                    </div>
                </div>
                <div className="row justify-content-around noPaddingSides noMarginSides">
                    <div className="col-5 terzakecol">
                        <div className="terzaketext nonbold">INNOPAY helpt organisaties al 20 jaar vol overtuiging met het in praktijk brengen van 'Alles transactie'. Neem gerust contact op.</div>
                    </div>
                    <div className="col-3 terzakecol">
                        <a href="http://innopay.com" target="_blank">
                            <img className="img-fluid innologo" src={innologo} alt="Error"/>
                        </a>
                    </div>
                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Block8;