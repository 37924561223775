import React from 'react';
import lannoologo from './lannoologo.png';
import innopay_footer from './innopay_footer.png';

class Block9 extends React.Component {

    render() {
        return (
            <div className="row blok9">
                <div className="col footercol">
                    <div>
                    <img className="innopay_footer" src={innopay_footer} alt="Error" />
                    </div>
                </div>
                <div className="col footercol">
                    <div className="blok9subtitel">Contact</div>
                    <div className="blok9text"><br/><a href="tel:+31206580651">Innopay: +31 20 6580651</a><br/><a href="mailto:book@everythingtransaction.com">book@everythingtransaction.com</a></div>
                </div>
                <div className="col footercol">
                    <div className="blok9subtitel">Legal</div>
                    <div className="blok9text"><br/><a href="/privacyen">Privacy Statement</a><br/>(c) Liezenberg, Lycklama, Nijland.<br/>All rights reserved</div>
                </div>
            </div>
        );
    }
}

export default Block9;