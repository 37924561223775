import React from 'react';
import { Parallax, Background } from 'react-parallax';
import blackArrow from "./blackarrow.png";

class Block4 extends React.Component {
    render() {
        return (


            <div>
            <Parallax className="noPaddingSides" strength={1000}
                      style={{marginLeft: -15, marginRight: -15}}
                      bgImage={require('../components/fotobackground.jpg')}>
                <div className="row blok4">
                    <div className="col-12">
                        <div className="blok4text">Authored by experienced fintech pionieers</div>
                    </div>
                    <div className="col-12">
                        <div className="blok4text text2">Based on years of hands-on experience with the development of innovative transaction platforms.</div>
                    </div>
                </div>
            </Parallax>
                <div className="row blok4mobilegoud">
                    <div className="col">
                        <div className="blok4goudtext links">
                            <b>SHIKKO NIJLAND</b> is CEO and managing partner of INNOPAY and has over 20 years of international experience in management consulting, from his time at EY, KPMG and Accenture.
                        </div>
                    </div>
                    <div className="col">
                        <div className="blok4goudtext midden">
                            <b>CHIEL LIEZENBERG</b> started INNOPAY as a hands-on entrepreneur with a passion for technology, product design, start-up and ‘connection’. He holds multiple patents.
                        </div>
                    </div>
                    <div className="col">
                        <div className="blok4goudtext rechts">
                            <b>DOUWE LYCKLAMA</b> is co-founder of INNOPAY and a thought leader in payment, invoicing, identity, data sharing and applicable regulation, with an extensive network in fintech.
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="blok4quote">"The next stage of the internet is about the shift from institutional to infrastructural trust,<br/> and getting a grip on your own data."</div>
                    </div>
                    <div className="col-12">
                        <img className="img-fluid blackarrow" src={blackArrow} alt="Error" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Block4;