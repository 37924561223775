import React, { Component } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import CookiesBar from './components/CookieBar';
import Blok9 from './components/Block9';
import arrowLeft from './components/arrow-left.png';

class PrivacyNL extends Component {
    componentDidMount() {
        document.title = "Privacy";
    }

    render() {
        return (
            <div className="lol">
            <div className="App">
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
                      integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u"
                      crossOrigin="anonymous"/>

                <link rel="stylesheet"
                      href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap-theme.min.css"
                      integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp"
                      crossOrigin="anonymous"/>
                <div>
                    <div>
                <div className="main mainwrapperpriv">
                    <div className="container-fluid noPaddingSides">
                        <div className="col"/>
                        <div className="col">
                            <div className="mainColPriv">
                                <div className="privacyblok marginmin15">
                                    <div className="row noMarginSides noPaddingSides">
                                        <CookiesBar/>
                                        <div className="col-12">
                                            <a href="/nl"><img src={arrowLeft} alt="Error" className="privacyarrow"/></a>
                                            <div className="privacytitel">Privacyverklaring</div>
                                        </div>
                                        <div className="col">
                                            <div className="privacysubtitel">Formulieren</div>
                                            <div className="privacytext">Om informatie te ontvangen van partijen die geïnteresseerd zijn in onze diensten, vragen we geïnteresseerde partijen een online formulier in te vullen.</div>
                                            <div className="privacysubtitel">Databeveiliging</div>
                                            <div className="privacytext">We zijn volledig toegewijd aan databeveiliging. Om niet-toegestane toegang te voorkomen en om integriteit van data en juist gebruik ervan door geautoriseerde gebruikers te waarborgen,  hebben we fysieke, elektronische en procedurele  maatregelen genomen om de informatie die je ons hebt gegeven te bewaken en beveiligen. Echter, ondanks ons streven om je persoonlijke gegevens te beschermen, kunnen we de beveiliging tijdens de verzending over het internet niet garanderen.
                                                In dat opzicht adviseren we elke maatregel te nemen om je persoonlijke gegevens te beschermen tijdens je gebruik van het internet.</div>
                                            <div className="privacysubtitel">Dataprivacy</div>
                                            <div className="privacytext">Informatie de we verzamelen kunnen persoonsgegevens omvatten, zoals naam, telefoonnummer en e-mailadres. We behandelen deze informatie als vertrouwelijk. We geven deze niet aan derden, tenzij we bij wet verplicht zijn dit te doen of indien het nodig is om onze diensten aan je te leveren. We behouden ons het recht voor om, ter onzer discretie, deze verklaring op enig moment te wijzigen. Check regelmatig of er  wijzigingen zijn.</div>
                                            <div className="privacysubtitel">Delen van data</div>
                                            <div className="privacytext">Omdat we internationaaal actief zijn, kan het nodig zijn je gegevens binnen onze organisatie over te dragen, ook naar onze leveranciers of zakenpartners, enkel om aan je verzoek(en) te voldoen. Deze entiteiten zijn verplicht om de vertrouwelijkheid van de informatie te waarborgen en zijn beperkt in het gebruik ervan tot enkel het helpen van ons om onze diensten aan je te leveren. We delen verder geen data met en verkopen geen data aan derden, zonder je toestemming.</div>
                                            <div className="privacysubtitel">Identificatiedata</div>
                                            <div className="privacytext">Wanneer je ons vrijwillig persoonsgegevens geeft zoals je naam, telefoonnummer en/of emailadres of andere contactinformatie, gebruiken we die om met je te corresponderen. In de toekomst kunnen we die ook gebruiken om je nieuwsbrieven te sturen. </div>
                                            <div className="privacysubtitel">Browsen</div>
                                            <div className="privacytext">Als je door onze website browst, doe je dat anoniem. We kunnen je IP-adres (het internetadres van je computer) gebruiken om onze website te beheren en om statistische gegevens te verzamelen voor intern gebruik over onze bezoekers, zoals bezoekspatronen, verblijfstijd op een pagina, e.d.</div>
                                            <div className="privacysubtitel">Cookies</div>
                                            <div className="privacytext">Cookies zijn kleine stukjes informatie die door je browser worden opgeslagen op je apparaat. We gebruiken cookies om je gebruikerssessie te volgen. We slaan geen vertrouwelijke of persoonlijke informatie op.</div>
                                        </div>
                                    </div>
                                    <div className="privacyfooter">
                                <Blok9></Blok9>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col"/>
                    </div>
                </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default PrivacyNL;