import React from 'react';
import ScrollableAnchor from "react-scrollable-anchor";
import Block5Mobile from "./Block5Mobile";
import Block5 from "./Block5";
import diagImage from "./chartexplode_resized.png";

class Block2Mobile extends React.Component {

    render() {
        return (
            <div className="row blok2">
                <div className="col">
                    <div className="blok2text">‘Everything transaction’ is about digitisation and how the Internet offers ever more far-reaching possibilities for that.</div>
                    <div className="blok2text text2">On this ‘online platform’ we will walk you through the three development stages of the Internet, just like in the book. We first look at 'information' followed by 'interaction' and, finally, 'transaction', given sufficient trust.</div>
                    <div className="diagramImage"></div>
                </div>
                <div className="col">
                    <img src={diagImage} className="img-responsive" alt="Image"/>
                    <div className="blok2text text3">This book helps leaders to better navigate the digital world, from the unique perspective  of  ‘transactions’.</div>
                </div>
            </div>
        );
    }
}

export default Block2Mobile;