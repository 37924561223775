import React from 'react';
import awardImage from "./award_image.png";

class Block3 extends React.Component {
    render() {
        return (
            <div className="blok32">
                <a href="https://www.managementboekvanhetjaar.nl" target="_blank">
                    <div className="row noMarginSides">
                        <div className="col-12">
                            <div className="award_titel">‘Managementboek van het Jaar’ 2019</div>
                        </div>
                        <div className="col-4 blok32col">
                            <div>
                                <div>
                            <img className="img-fluid award_image" src={awardImage} alt="Error" />
                                </div>
                            </div>
                        </div>
                        <div className="col-8 blok32col">
                            <div className="award_text">“Vanwege het maatschappelijk en economisch belang van het onderwerp, vanwege de noodzaak voor eigenlijk iedere organisatie om zich daarmee te verstaan, vanwege de grondige analyse, vanwege de heldere ontwikkelrichting, vanwege de agenda die een veelheid aan stakeholders op hun verantwoordelijkheden wijst en ondanks het feit dat het van alle beoordeelde boeken misschien wel het meest van de lezer vergt – daarom koos de jury unaniem voor 'Alles transactie' van Chiel Liezenberg, Douwe Lycklama en Shikko Nijland”, zo luidt het oordeel van de jury.
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        );
    }
}

export default Block3;