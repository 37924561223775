import React from 'react';
import ScrollableAnchor from "react-scrollable-anchor";
import Block5Mobile from "./Block5Mobile";
import Block5 from "./Block5";
import diagImage from "./chartexplode_resized.png";

class Block2Mobile extends React.Component {
    render() {
        return (
            <div className="row blok2">
                <div className="col">
                    <div className="blok2text">'Alles transactie' gaat over digitalisering en hoe het internet daar steeds verdergaande mogelijkheden toe biedt.</div>
                    <div className="blok2text text2">Op dit 'online platform' doorlopen we net als in het boek de drie ontwikkelingsfasen van het internet. Eerst krijg je 'informatie', dan nodigen we je uit tot 'interactie', om daarna -bij voldoende vertrouwen- tot 'transactie' te komen.</div>
                    <div className="diagramImage"></div>
                </div>
                <div className="col">
                    <img src={diagImage} className="img-responsive" alt="Image"/>
                    <div className="blok2text text3">Het boek helpt leiders nog beter te navigeren in de digitale wereld, vanuit het unieke perspectief van 'transacties'.</div>
                </div>
            </div>
        );
    }
}

export default Block2Mobile;