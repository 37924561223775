import React from 'react';
import whiteArrow from "../components/whitearrow.png";


class Block7 extends React.Component {

    render() {
        return (
            <div className="row blok7">
                <div className="col-12">
                    <img className="img-fluid whitearrow" src={whiteArrow} alt="Error" />
                </div>
                <div className="col blok7col">
                    <div className="informatieTitel">transaction</div>
                    <div className="transactiesubtitel">Are we ready? Is there enough trust to make a transaction...</div>
                    <div className="transactietext">No. At least, not here. This platform simply cannot provide the required trust. Luckily there are others that can do this on their specialised platforms. OK, it may require a whole series of interactions, but it’s possible! </div>
                </div>
            </div>
        );
    }
}

export default Block7;