import React from 'react';
import Title from "./Title";
import boekImage from "./boek2_innopay.png";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import CookieBar from './CookieBar';

class Block1 extends React.Component {
    link = () => {
        window.location.href = '/nl';
    }

    de = () => {
        window.location.href = '/de'
    }

    en = () => {
        window.location.href = '/en'
    }

    nl = () => {
        window.location.href = '/nl';
    }

    render() {
        return (
            <div className="blok1 marginmin15">
                <div className="row noMarginSides noPaddingSides">
                        <CookieBar/>
                    <div className="col-12 award_bar">
                        <div className="award_bar_text"><AnchorLink href="#award"> Dutch ‘Management Book of the Year’ 2019 </AnchorLink></div>
                    </div>
                    <div className="col">
                        <a target="_blank" href="https://www.amazon.com/dp/B07YZ8ND93" className="buyButton">Buy now</a>
                    </div>
                    <div className="col">
                        <div>
                        <li className="displayinline">
                            <div className="langButton"><strong onClick={this.nl}>NL |</strong> EN <strong onClick={this.de}>| DE</strong></div>
                        </li>
                        </div>
                    </div>
                </div>
            <div className="row noMarginSides noPaddingSides">

                <div className="col clickthrough">
                    <Title></Title>
                </div>
                <div className="col">
                    <img className="img-fluid boekImage" src={boekImage} alt="Error" style={{}} />
                </div>
            </div>
            </div>
        );
    }
}

export default Block1;
