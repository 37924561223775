import React from 'react';
import Title from "./Title";
import boekImage from "./boek2NL.png";
import CookieBar from './CookieBar';
import AnchorLink from 'react-anchor-link-smooth-scroll'

class Block1 extends React.Component {
    link = () => {
        window.location.href = '/en';
    }

    de = () => {
        window.location.href = '/de'
    }

    en = () => {
        window.location.href = '/en'
    }

    nl = () => {
        window.location.href = '/nl';
    }

    render() {
        return (
            <div className="blok1 marginmin15">
                <div className="row noMarginSides noPaddingSides">
                    <CookieBar/>
                    <div className="col-12 award_bar">
                        <div>
                        <div className="award_bar_text"><AnchorLink href="#award">Verkozen tot ‘Managementboek van het Jaar’ 2019</AnchorLink></div>
                        </div>
                    </div>
                    <div className="col">
                        <div>
                        <a target="_blank" href="https://www.managementboek.nl/boek/9789401457095/alles-transactie-chiel-liezenberg?affiliate=6489" className="buyButton">Direct kopen</a>
                        </div>
                    </div>
                    <div className="col">
                        <div>
                        <li className="displayinline">
                            <div className="langButton">NL <strong>|<span onClick={this.en}> EN </span>|<span onClick={this.de}> DE</span></strong></div>
                        </li>
                        </div>
                    </div>
                </div>
            <div className="row noMarginSides noPaddingSides">

                <div className="col clickthrough">
                    <Title></Title>
                </div>
                <div className="col">
                    <div>
                        <div>
                        <img className="img-fluid boekImage" src={boekImage} alt="Error" style={{}} />
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default Block1;
