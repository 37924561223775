import React from 'react';

class Title extends React.Component {
    render() {
        return (
            <div>
                <div className="titlequote quote2">A transaction always has <br/>two actors that complete<br/> three processes together.</div>
                <div className="titleColumn">
                    <div className="title">Hello, welcome to</div>
                    <div className="title groot">'Everything transaction'</div>
                </div>
                <div className="titlequote quote1" style={{}}>In the digital domain,<br/>trust manifests itself<br/>in the form of data.</div>
                <div className="titleColumn sequential">
                    <div className="title">About data, trust and the unprecedented opportunities of the transactional internet</div>
                </div>
                <div className="titlequote quote3" style={{marginBottom: "0", opacity: ".15"}}>The next fase of the internet<br/>is about the shift from institutional<br/>to infrastructural trust.</div>
            </div>
        );
    }
}

export default Title;