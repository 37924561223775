import React from 'react';

class Title extends React.Component {
    render() {
        return (
            <div>
                <div className="titlequote quote2">
                  Eine Transaktion umfasst immer<br/> zwei Akteure, die gemeinsam<br/> drei Prozesse durchlaufen.
                </div>
                <div className="titleColumn">
                    <div className="title">Hallo und herzlich willkommen bei</div>
                    <div className="title groot">„Alles Transaktion“</div>
                </div>
                <div className="titlequote quote1" style={{}}>
                  In der digitalen Welt<br/> nimmt Vertrauen die Form<br/> von Daten an.
                  {/*In the digital domain,<br/>trust manifests itself<br/>in the form of data.*/}
                </div>
                <div className="titleColumn sequential">
                    <div className="title">Über Daten, Vertrauen und die beispiellosen Möglichkeiten des transaktionalen Internets</div>
                </div>
                <div className="titlequote quote3" style={{marginBottom: "0", opacity: ".15"}}>
                  In der nächsten Phase des Internets<br/> geht es um den Übergang von institutionellem<br/> zu infrastrukturellem Vertrauen.
                </div>
            </div>
        );
    }
}

export default Title;
