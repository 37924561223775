import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css'
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import {Router, Route, browserHistory, IndexRoute} from 'react-router'
import EN from './EN';
import NL from './NL';
import PrivacyNL from './PrivacyNL';
import PrivacyEN from './PrivacyEN';
//import { hydrate, render } from 'react-dom';
import { render } from 'react-snapshot';

    render((
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    ), document.getElementById('root'));


    /*const rootElement = document.getElementById('root');
    if (rootElement.hasChildNodes()) {
        ReactDOM.hydrate(<BrowserRouter>
            <App />
        </BrowserRouter>, rootElement);
    } else {
        ReactDOM.render(<BrowserRouter>
            <App />
        </BrowserRouter>, rootElement);
    }
    */
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister();
