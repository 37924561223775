import React from 'react';
import { Parallax, Background } from 'react-parallax';
import blackArrow from "./blackarrow.png";

class Block4 extends React.Component {
    render() {
        return (


            <div>
            <Parallax className="noPaddingSides" strength={1000}
                      style={{marginLeft: -15, marginRight: -15}}
                      bgImage={require('../components/fotobackground.jpg')}>
                <div className="row blok4">
                    <div className="col-12">
                        <div className="blok4text">Von erfahrenen FinTech-Pionieren geschrieben</div>
                    </div>
                    <div className="col-12">
                        <div className="blok4text text2">Auf der Grundlage jahrelanger praktischer Erfahrungen mit der Entwicklung von innovativen Transaktionsplattformen.</div>
                    </div>
                </div>
            </Parallax>
                <div className="row blok4mobilegoud">
                    <div className="col">
                        <div className="blok4goudtext links">
                            <b>SHIKKO NIJLAND</b> ist CEO und Managing Partner von INNOPAY und verfügt über mehr als 20 Jahre internationale Erfahrung in der Managementberatung, die er in seiner Zeit bei EY, KPMG und Accenture gesammelt hat.
                        </div>
                    </div>
                    <div className="col">
                        <div className="blok4goudtext midden">
                            <b>CHIEL LIEZENBERG</b> hat INNOPAY gegründet und ist ein praxisorientierter Unternehmer, der für Technologie, Produktdesign, Start-ups und „Verbindungen“ brennt. Er hält verschiedene Patente.
                        </div>
                    </div>
                    <div className="col">
                        <div className="blok4goudtext rechts">
                            <b>DOUWE LYCKLAMA</b> ist Mitgründer von INNOPAY und ein Vordenker mit Blick auf Zahlungen, Rechnungsstellung, Identität, das Teilen von Daten und die einschlägigen Vorschriften. Er verfügt über ein umfangreiches Netzwerk in der FinTech-Welt.
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="blok4quote">„In der nächsten Phase des Internets geht es darum, den Übergang von institutionellem zu infrastrukturellem Vertrauen zu vollziehen und die Kontrolle über die eigenen Daten zu erlangen.“</div>
                    </div>
                    <div className="col-12">
                        <img className="img-fluid blackarrow" src={blackArrow} alt="Error" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Block4;
