import React from 'react';
import twitterImg from "./twitter.png";
import linkedinImg from "./linkedin.png";
import facebookImg from "./facebook.png";
import mailImg from "./mail.png";
import phoneImg from "./phone.png";
import MailchimpSubscribe from "react-mailchimp-subscribe"
import whiteArrow from "./whitearrow.png";

class Block7 extends React.Component {

    render() {
        return (
            <div className="row blok7">
                <div className="col-12">
                    <img className="img-fluid whitearrow" src={whiteArrow} alt="Error" />
                </div>
                <div className="col blok7col">
                    <div className="informatieTitel">transactie</div>
                    <div className="transactiesubtitel">Zijn we zover? Is er voldoende vertrouwen om over te gaan tot transactie...</div>
                    <div className="transactietext">Nee, althans niet hier. Dit platform kan dit vertrouwen simpelweg niet bieden. Gelukkig zijn er anderen die dit met hun toegespitste platforms wel kunnen. Weliswaar met een hele serie interacties, maar OK, het kan!</div>
                </div>
            </div>
        );
    }
}

export default Block7;