import React from 'react';
import lannoologo from './lannoologo.png';

class Block9 extends React.Component {

    render() {
        return (
            <div className="row blok9">
                <div className="col footercol">
                    <img className="lannoologo" src={lannoologo} alt="Error" />
                </div>
                <div className="col footercol">
                    <div className="blok9subtitel">Contact</div>
                    <div className="blok9text"><br/><a href="tel:+31307888300">Lannoo: +31 30 799 8300</a><br/><a href="tel:+31206580651">Innopay: +31 20 6580651</a><br/><a href="mailto:boek@allestransactie.nl">boek@allestransactie.nl</a></div>
                </div>
                <div className="col footercol">
                    <div className="blok9subtitel">Juridisch</div>
                    <div className="blok9text"><br/><a href="/privacynl">Privacyverklaring</a><br/>(c) Liezenberg, Lycklama, Nijland.<br/>Alle rechten voorbehouden</div>
                </div>
            </div>
        );
    }
}

export default Block9;