import React from 'react';
import awardImage from "./award_image.png";

class Block3 extends React.Component {
    render() {
        return (
            <div className="blok32">
                <a href="https://www.managementboekvanhetjaar.nl" target="_blank">
                    <div className="row noMarginSides">
                        <div className="col-12">
                            <div className="award_titel">‘Management Book of the Year’ 2019</div>
                        </div>
                        <div className="col-4 blok32col">
                            <div>
                                <div>
                            <img className="img-fluid award_image" src={awardImage} alt="Error" />
                                </div>
                            </div>
                        </div>
                        <div className="col-8 blok32col">
                            <div className="award_text">“Because of the social and economic relevance of the subject, because of the need for basically every organisation to understand it, because of the thorough analysis, because of the clear development direction, because of the agenda that points out their responsibility to a multitude of stakeholders and despite the fact that of all books reviewed it perhaps asks the most of the reader – that is why the jury unanimously choose ‘Everything Transaction’ by Chiel Liezenberg, Douwe Lycklama and Shikko Nijland”,  according to the jury.
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        );
    }
}

export default Block3;