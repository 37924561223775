import React from 'react';
import { Parallax, Background } from 'react-parallax';
import blackArrow from "./blackarrow.png";

class Block4 extends React.Component {
    render() {
        return (
            <div>
            <Parallax className="noPaddingSides" strength={1000}
                      style={{marginLeft: -15, marginRight: -15}}
                      bgImage={require('./fotobackground.jpg')}>
                <div className="row blok4">
                    <div className="col-12">
                        <div className="blok4text">Van de hand van ervaren fintech pioniers</div>
                    </div>
                    <div className="col-12">
                        <div className="blok4text text2">Puttend uit jarenlange, hands-on ervaring met de ontwikkeling van innovatieve transactieplatforms.</div>
                    </div>
                </div>
            </Parallax>
                <div className="row blok4mobilegoud">
                    <div className="col">
                        <div className="blok4goudtext links">
                            <b>SHIKKO NIJLAND</b> is CEO en managing partner van INNOPAY en heeft meer dan 20 jaar internationale ervaring in management consulting, opgedaan bij EY, KPMG en Accenture.
                        </div>
                    </div>
                    <div className="col">
                        <div className="blok4goudtext midden">
                            <b>CHIEL LIEZENBERG</b> begon INNOPAY als hands-on ondernemer met een passie voor technologie, product design, start-up en verbinding. Hij is houder van meerdere octrooien.
                        </div>
                    </div>
                    <div className="col">
                        <div className="blok4goudtext rechts">
                            <b>DOUWE LYCKLAMA</b> is oprichter van INNOPAY en thought leader in betalen, factureren, identity, data delen en toepasselijke regelgeving, met een uitgebreid netwerk in fintech.
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="blok4quote">"De volgende fase van het internet gaat over de shift van institutioneel naar infrastructureel vertrouwen,<br/> en grip op je eigen data."</div>
                    </div>
                    <div className="col-12">
                        <img className="img-fluid blackarrow" src={blackArrow} alt="Error" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Block4;