import React from 'react';
import lannoologo from './lannoologo.png';
import innopay_footer from './innopay_footer.png';

class Block9 extends React.Component {

    render() {
        return (
            <div className="row blok9">
                <div className="col footercol">
                    <div>
                    <img className="innopay_footer" src={innopay_footer} alt="Error" />
                    </div>
                </div>
                <div className="col footercol">
                    <div className="blok9subtitel">Kontakt</div>
                    <div className="blok9text"><br/><a href="tel:+31206580651">Innopay: +31 20 6580651</a><br/><a href="mailto:buch@allestransaktion.de">buch@allestransaktion.de</a></div>
                </div>
                <div className="col footercol">
                    <div className="blok9subtitel">Rechtliches</div>
                    <div className="blok9text"><br/><a href="/privacyen">Datenschutzerklärung</a><br/>(c) Liezenberg, Lycklama, Nijland.<br/>Alle Rechte vorbehalten.</div>
                </div>
            </div>
        );
    }
}

export default Block9;
