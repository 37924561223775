import React from 'react';
import SurpiseBox from './surpisebox_resized.png'
import boekImage from "./boekflipped2NL.png";

class Block3 extends React.Component {
    render() {
        return (
            <div className="blok3">
                <div className="row noMarginSides">
                    <div className="col-12">
                        <div className="informatieTitel">informatie</div>
                    </div>
                    <div className="col blok3col">
                        <div>
                            <div>
                        <img className="img-fluid boekImage2" src={boekImage} alt="Error" />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="blok3text groot">Geïntegreerd perspectief op de kern van het koopproces</div>
                        <div className="blok3text">Interacties en transacties zijn als ademhalen. We zijn er de hele dag mee bezig, zonder dat we erbij stilstaan. Ze variëren van bellen tot betalen tot inloggen. Maar wat zijn transacties eigenlijk?</div>
                    </div>
                </div>
                <div className="row noMarginSides">
                    <div className="col">
                        <div className="blok3text groot zwart">Bomvol tijdloze inzichten over digitalisering</div>
                        <div className="blok3text zwart">Het boek beschrijft de samenhang tussen transacties, het koopproces, tweezijdige markten, platforms, data en vertrouwen. Toegelicht met herkenbare cases, heldere achtergronden, modellen en figuren in een eigen beeldtaal.</div>
                    </div>
                    <div className="col">
                            <img src={SurpiseBox} className="img-responsive diagramImage2" alt="Responsive image"/>
                    </div>
                    <div className="col-12">
                        <div className="informatieSubTitel">Het enige boek over hoe we controle houden over de voortschrijdende digitalisering en platformatie</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Block3;