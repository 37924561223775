import React from 'react';
import innologo from './innopaylogo.png';
import blackArrow from "./blackarrow2.png";

class Block8 extends React.Component {

    render() {
        return (
            <div className="blok8 marginmin15">
                <div className="row noPaddingSides noMarginSides">
                    <div className="col-12">
                        <img className="img-fluid blackarrow" src={blackArrow} alt="Error" />
                    </div>
                    <div className="col">
                        <div className="terzaketitel">Kommen wir zum Geschäftlichen!</div>
                    </div>
                </div>
                <div className="row justify-content-around noPaddingSides noMarginSides">
                    <div className="col-4 terzakecol">
                        <div className="terzakesubtitel">Bestellen Sie das Buch</div>
                      <div className='yo'>
                        <a className="directbuybutton" target="_blank" href="https://www.amazon.de/Chiel-Liezenberg/dp/9490587125/ref=mp_s_a_1_1?crid=1X2489MPHE9EY&keywords=alles+transaktion&qid=1670360452&sprefix=alles+transaktion%2Caps%2C71&sr=8-1">Amazon.de</a>
                      </div>
                        {/*<a className="directbuybutton directbuy_second" target="_blank" href="https://www.managementboek.nl/boek/9789401457095/alles-transactie-chiel-liezenberg?affiliate=6489">Managementboek.nl</a>*/}

                    </div>
                    <div className="col-4 terzakecol">
                        <div className="terzakesubtitel">Werfen Sie erst einen Blick in das Buch</div>
                        <div className="terzaketext">Natürlich finden bald Sie „Alles Transaktion“ auch im Buchhandel. Dort können Sie durch dieses wundervolle Buch blättern und sich inspirieren lassen.</div>
                    </div>
                </div>
                <div className="row noPaddingSides noMarginSides">
                    <div className="col-12">
                        <div className="terzakesubtitel">Hinweis zu „Alles Transaktion“</div>
                    </div>
                </div>
                <div className="row justify-content-around noPaddingSides noMarginSides">
                    <div className="col-5 terzakecol">
                        <div className="terzaketext nonbold">INNOPAY hilft Unternehmen seit mehr als 20 Jahren, „Alles Transaktion“ praktisch umzusetzen. Sprechen Sie uns an.</div>
                    </div>
                    <div className="col-3 terzakecol">
                        <a href="http://innopay.com" target="_blank">
                            <img className="img-fluid innologo" src={innologo} alt="Error"/>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Block8;
