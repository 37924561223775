import React from 'react';
import SurpiseBox from './surpisebox_resized.png'
import boekImage from "./boekflipped4.png";

class Block3 extends React.Component {
    render() {
        return (
            <div className="blok3">
                <div className="row noMarginSides">
                    <div className="col-12">
                        <div className="informatieTitel">information</div>
                    </div>
                    <div className="col blok3col">
                        <img className="img-fluid boekImage2" src={boekImage} alt="Error" />
                    </div>
                    <div className="col">
                        <div className="blok3text groot">Integrated perspective on the core of the buying process</div>
                        <div className="blok3text">Interactions and transactions are like breathing. We do it the whole day, without thinking about it. They vary from making a call or chatting, to paying or logging in. But what are transactions, really?</div>
                    </div>
                </div>
                <div className="row noMarginSides">
                    <div className="col">
                        <div className="blok3text groot zwart">Filled to the rim with timeless insights on digitisation</div>
                        <div className="blok3text zwart">The book describes the relation between transactions, the buying process, 2-sided markets, platforms, data and trust. Explained through recognisable cases, clear backgrounds, models and illustrations in its own visual language.</div>
                    </div>
                    <div className="col">
                            <img src={SurpiseBox} className="img-responsive diagramImage2" alt="Responsive image"/>
                    </div>
                    <div className="col-12">
                        <div className="informatieSubTitel">The only book on how we can keep control over the ongoing digitisation and platformation </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Block3;