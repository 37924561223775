import React from 'react';
import QuoteCarousel from './QuoteCarousel'
import QuoteCarouselMobile from './QuoteCarouselMobile'

class Block5 extends React.Component {
    state = {
        quoteText1: undefined,
        quoteAuteur1: undefined,
        quoteImage1: undefined,

        quoteText2: undefined,
        quoteAuteur2: undefined,
        quoteImage2: undefined,

        quoteText3: undefined,
        quoteAuteur3: undefined,
        quoteImage3: undefined,

        isMobile: false
    }

    constructor(props) {
        super(props);
        this.state = {
            isMobile: false
        };

        this.updatePredicate = this.updatePredicate.bind(this);
    }
    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
    }

    updatePredicate() {
        this.setState({ isMobile: window.innerWidth < 768 });
    }

    render() {
        const isMobile = this.state.isMobile;

        return (
            <div className="blok5">
                <div className="row noMarginSides">
                    <div className="col noPaddingSides">
                        <div className="informatieTitel zwart">interactie</div>
                        <div className="interactieSubtitel">Maak zelf het laatste nieuws over</div>
                        <div className="interactieSubtitel hashtag">#allestransactie</div>
                    </div>
                </div>
                <div className="row carouselRow marginmin15">
                    <div className="col">
                        <QuoteCarousel/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Block5;