import React from 'react';
import SurpiseBox from './surpisebox_resized.png'
import boekImage2 from "./boekflipped4.png";

class Block3 extends React.Component {
    render() {
        return (
            <div className="blok3">
                <div className="row noMarginSides">
                    <div className="col-12">
                        <div className="informatieTitel">Information</div>
                    </div>
                    <div className="col blok3col">
                        <div>
                            <l>
                        <img className="img-fluid boekImage2" src={boekImage2} alt="Error" />
                            </l>
                        </div>
                    </div>
                    <div className="col">
                        <div className="blok3text groot">Umfassende Betrachtung des Kerns des Kaufprozesses</div>
                        <div className="blok3text">Interaktionen und Transaktionen sind wie Atmen. Wir tun es die ganze Zeit, ohne darüber nachzudenken. Sie kommen in ganz unterschiedlicher Form daher – von Telefonanrufen über den elektronischen Zahlungsverkehr bis hin zum Login in sozialen Netzwerken. Aber was ist eigentlich das Wesen von Transaktionen?</div>
                    </div>
                </div>
                <div className="row noMarginSides">
                    <div className="col">
                        <div className="blok3text groot zwart">Randvoll mit zeitlosen Erkenntnissen rund um Digitalisierung</div>
                        <div className="blok3text zwart">Das Buch behandelt die Beziehung zwischen Transaktionen, den Kaufprozess, zweiseitige Märkte, Plattformen, Daten und Vertrauen – erklärt anhand von Fallbeispielen mit Wiedererkennungswert sowie klaren Hintergrundinformationen, Modellen und Illustrationen mit einer ganz eigenen visuellen Sprache.</div>
                    </div>
                    <div className="col">
                            <img src={SurpiseBox} className="img-responsive diagramImage2" alt="Responsive image"/>
                    </div>
                    <div className="col-12">
                        <div className="informatieSubTitel">Das einzige Buch, das aufzeigt, wie wir die laufende Digitalisierung und Plattformation kontrollieren können.</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Block3;
