import React from 'react';

class Block4Mobile extends React.Component {
    render() {
        return (
            <div>


                <div className="row blok4mobilegoud">
                    <div className="col">
                        <div className="blok4mobiletext">
                            <b>SHIKKO NIJLAND</b> is CEO and managing partner of INNOPAY and has over 20 years of international experience in management consulting, from his time at EY, KPMG and Accenture.
                        </div>
                    </div>
                    <div className="col">
                        <div className="blok4mobiletext">
                            <b>CHIEL LIEZENBERG</b> started INNOPAY as a hands-on entrepreneur with a passion for technology, product design, start-up and ‘connection’. He holds multiple patents.
                        </div>
                    </div>
                    <div className="col">
                        <div className="blok4mobiletext drie">
                            <b>DOUWE LYCKLAMA</b> is co-founder of INNOPAY and a thought leader in payment, invoicing, identity, data sharing and applicable regulation, with an extensive network in fintech.
                        </div>
                    </div>
                </div>
                <div className="row blok4mobile">
                    <div className="col-12">

                    </div>
                </div>
            </div>
        );
    }
}

export default Block4Mobile;