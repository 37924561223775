import React, { Component } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import CookiesBar from './components_engels/CookieBar';
import Blok9 from './components_engels/Block9';
import arrowLeft from './components/arrow-left.png';

class PrivacyNL extends Component {
    componentDidMount() {
        document.title = "Privacy";
    }

    render() {
        return (
            <div className="lol">
            <div className="App">
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
                      integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u"
                      crossOrigin="anonymous"/>

                <link rel="stylesheet"
                      href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap-theme.min.css"
                      integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp"
                      crossOrigin="anonymous"/>
                <div>
                    <div>
                <div className="main mainwrapperpriv">
                    <div className="container-fluid noPaddingSides">
                        <div className="col"/>
                        <div className="col">
                            <div className="mainColPriv">
                                <div className="privacyblok marginmin15">
                                    <div className="row noMarginSides noPaddingSides">
                                        <CookiesBar/>
                                        <div className="col-12">
                                            <a href="/en"><img src={arrowLeft} alt="Error" className="privacyarrow"/></a>
                                            <div className="privacytitel">Privacy Statement</div>
                                        </div>
                                        <div className="col">
                                            <div className="privacysubtitel">Forms</div>
                                            <div className="privacytext">In order to receive information from parties interested in our services, we request that interested parties complete forms that are available online.</div>
                                            <div className="privacysubtitel">Data security</div>
                                            <div className="privacytext">We are fully committed to data security. To prevent unauthorized access, maintain data accuracy and ensure the appropriate usage of the information by authorized users, we have put in place physical, electronic and organizational procedures to safeguard and secure the information you provided to us online. However, while we strive to protect your personal information, we cannot ensure the security of the information during transmission through the Internet. In this regard, we advise you to take every precaution to protect your personal data while you are using the Internet.</div>
                                            <div className="privacysubtitel">Data privacy</div>
                                            <div className="privacytext">Information we collect may include personal data related to individuals such as name, phone and email address. We treat all this information as confidential. We do not give it or sell it to any third party, except as required by law or as necessary for us to provide you with the required services. We reserve the right, at our discretion, to add, modify, or remove portions of this policy at any time. Please check it periodically for changes.</div>
                                            <div className="privacysubtitel">Sharing your data</div>
                                            <div className="privacytext">Because we operate internationally, we may transfer data you submitted to us within our organization, including our sub-contractors or business partners strictly in order to fulfill your request(s). These entities are required to maintain the confidentiality of the information and are restricted from using it for any purpose other than helping us to provide you with the required services. Other than that, we will not share information with or sell the information to any third parties, without your consent.</div>
                                            <div className="privacysubtitel">Identification data</div>
                                            <div className="privacytext">When you voluntarily supply us with your personal information such as your name, phone number and/or email address or other contact information, we will use it to correspond with you. In the future, we may also use it to send you newsletters.</div>
                                            <div className="privacysubtitel">Browsing</div>
                                            <div className="privacytext">If you browse this website, you do so anonymously. We may use your IP address (the Internet address of your computer) to administer our website and to collect statistics for internal use only about our visitors, such as traffic patterns, time spent on a page, etc.</div>
                                            <div className="privacysubtitel">Cookies</div>
                                            <div className="privacytext">Cookies are small pieces of information that are stored by your browser on your computer's hard drive. We use cookies in order to track your user session. We do not store any confidential or personal information.</div>
                                        </div>
                                    </div>
                                    <div className="privacyfooter">
                                <Blok9></Blok9>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col"/>
                    </div>
                </div>
                </div>
                </div>
            </div>
            </div>
        );
    }
}

export default PrivacyNL;