import React from 'react';

class MobileQuote extends React.Component {
    render() {
        return (
          <div className="row">
              <div className="col-12 quoteLeftColumn">
                  <img className="quoteImage" src={this.props.quoteImage} />
              </div>
              <div className="col-12 quoteRightColumn">
                  <div className="quoteText">{this.props.quoteText}</div>
                  <div className="quoteText underscore">_</div>
                  <div className="quoteAuteur">{this.props.quoteAuteur}</div>
              </div>
          </div>

        );
    }
}

export default MobileQuote;