import React from 'react';
import whiteArrow from "../components/whitearrow.png";


class Block7 extends React.Component {

    render() {
        return (
            <div className="row blok7">
                <div className="col-12">
                    <img className="img-fluid whitearrow" src={whiteArrow} alt="Error" />
                </div>
                <div className="col blok7col">
                    <div className="informatieTitel">Transaktion</div>
                    <div className="transactiesubtitel">Sind wir bereit? Gibt es genug Vertrauen, um eine Transaktion abzuwickeln ...?</div>
                    <div className="transactietext">Nein. Zumindest nicht hier. Diese Plattform kann einfach nicht das nötige Vertrauen bieten. Zum Glück gibt es andere, die das auf ihren spezialisierten Plattformen tun können. Okay, vielleicht braucht es dazu eine ganze Reihe von Interaktionen – aber es ist möglich!</div>
                </div>
            </div>
        );
    }
}

export default Block7;
