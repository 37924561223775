import React from 'react';

class Title extends React.Component {
    render() {
        return (
            <div>
                <div className="titlequote quote2">Een transactie kent altijd<br/>twee actoren die samen<br/>drie processen doorlopen.</div>
                <div className="titleColumn">
                    <div className="title">Hallo, welkom bij</div>
                    <div className="title groot">'Alles transactie'</div>
                </div>
                <div className="titlequote quote1">In het digitale domein<br/>manifesteert vertrouwen<br/>zich in de vorm van data.</div>
                <div className="titleColumn sequential">
                    <div className="title">Over data, vertrouwen en de ongekende kansen van het transactionele internet</div>
                </div>
                <div className="titlequote quote3">De volgende fase van het internet<br/>gaat over de shift van institutioneel<br/>naar infrastructureel vertrouwen.</div>
            </div>

        );
    }
}

export default Title;