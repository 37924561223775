import React from 'react';
import Title from "./Title";
import boekImage2 from "./boek2_innopay.png";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import CookieBar from './CookieBar';

class Block1 extends React.Component {
    link = () => {
        window.location.href = '/nl';
    }

    de = () => {
        window.location.href = '/de'
    }

    en = () => {
        window.location.href = '/en'
    }

    nl = () => {
        window.location.href = '/nl';
    }

    render() {
        return (
            <div className="blok1 marginmin15">
                <div className="row noMarginSides noPaddingSides">
                        <CookieBar/>
                    <div className="col-12 award_bar">
                        <div className="award_bar_text"><AnchorLink href="#award"> Niederländisches „Management Book of the Year“ 2019 </AnchorLink></div>
                    </div>
                    <div className="col">
                        <div className='yo'>
                        <a target="_blank" href="https://www.amazon.de/Chiel-Liezenberg/dp/9490587125/ref=mp_s_a_1_1?crid=1X2489MPHE9EY&keywords=alles+transaktion&qid=1670360452&sprefix=alles+transaktion%2Caps%2C71&sr=8-1" className="buyButton">Jetzt kaufen</a>
                        </div>
                    </div>
                    <div className="col">
                        <div>
                        <li  className="displayinline">
                            <div className="langButton"><strong><span onClick={this.nl}>NL |</span> <span onClick={this.en}>EN</span> </strong>| DE</div>
                        </li>
                        </div>
                    </div>
                </div>
            <div className="row noMarginSides noPaddingSides">

                <div className="col clickthrough">
                    <Title></Title>
                </div>
                <div className="col">
                    <div>
                        <div>
                            <l>
                    <img className="img-fluid boekImage" src={boekImage2} alt="Error" style={{}} />
                            </l>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default Block1;
